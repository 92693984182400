import { gridColumn } from "@rxweb/grid"


export class vReportBase {

        //#region transectionDetailId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'transectionDetailId', keyColumn: true })
        transectionDetailId: number;
        //#endregion transectionDetailId Prop

        //#region userName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'userName', keyColumn: false, headerTitle: "User Name",configuredTemplate:{templateName:"linkRedirect"} })
        userName: string;
        //#endregion userName Prop

        //#region roleId Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'roleId', keyColumn: true, headerTitle: "Role", configuredTemplate: { templateName: "role" } })
        roleId: number;
        //#endregion roleId Prop   

        //#region transectionId Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'transectionId', keyColumn: false, headerTitle: "Transaction Id" })
        transectionId: string;
        //#endregion transectionId Prop
        

        //#region amount Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'amount', keyColumn: false, headerTitle: "Amount",  configuredTemplate: { templateName: "numberDisplay" }})
        amount: number;
        //#endregion amount Prop


        //#region paymentDoneOn Prop
        @gridColumn({ visible: true, columnIndex: 5, isAscending: true, allowSorting: true, headerKey: 'paymentDoneOn', keyColumn: false,headerTitle:"Transaction Date" ,configuredTemplate: { templateName: "dateFormat" } })
        paymentDoneOn: any;
        //#endregion paymentDoneOn Prop


        //#region isUSCompliance Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'isUSCompliance', keyColumn: false, headerTitle: "Payment Type" })
        isUSCompliance: string;
        //#endregion isUSCompliance Prop

        

        //#region responseCode Prop
        @gridColumn({ visible: false, columnIndex: 7, allowSorting: true, headerKey: 'responseCode', keyColumn: false, headerTitle: "Response Code" })
        responseCode: string;
        //#endregion responseCode Prop
        
        
        //#region subscriptionPlanName Prop
        @gridColumn({ visible: false, columnIndex: 8, allowSorting: true, headerKey: 'subscriptionPlanName', keyColumn: false, headerTitle: "Subscription Plan" })
        // @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'subject', keyColumn: false,headerTitle: "Start Date", configuredTemplate: { templateName: "dateFormat" }})
        subscriptionPlanName: string;
        //#endregion subscriptionPlanName Prop
        
        //#region isPaymentRecived Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'isPaymentRecived', keyColumn: false, headerTitle: "Payment Received",configuredTemplate: { templateName: "payment" } })
        isPaymentRecived: boolean;
        //#endregion isPaymentRecived Prop

        //#region responseDescription Prop
        @gridColumn({ visible: false, columnIndex: 10, allowSorting: true, headerKey: 'responseDescription', keyColumn: false, headerTitle: "Response Description" })
        responseDescription: string;
        //#endregion responseDescription Prop

        //#region expertiseCategory Prop
        @gridColumn({ visible: false, columnIndex: 11, allowSorting: true, headerKey: 'expertiseCategory', keyColumn: false})
        expertiseCategory: string;
        //#endregion expertiseCategory Prop
}