//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { vReportBase } from '../database-models/v-report-base';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'paymentReportAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vReport extends vReportBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






















}