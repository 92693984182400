import { required, email, maxLength } from '@rxweb/reactive-form-validators';

export class UserCredentialModel {
    @required({ message: "Email is required " })
    @email({ message: "Please enter valid email address" })
    @maxLength({ value: 50 })
    email: string = undefined;
    @required({ message: "Password is required " })
    @maxLength({ value: 15 })
    password: string = undefined;
}
