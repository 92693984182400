import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { CoreComponent } from '@rxweb/angular-router';
import { http } from '@rxweb/http';

@http({
    path: "api/SearchDashBoard/hrcDashBoard",
})
export class AbstractHRCDashboard extends CoreComponent {
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    isGuestHRC: boolean;
    isHRC: boolean;
    
    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
        this.isGuestHRC = window.location.href.indexOf('guest-hrc-dashboard') !== -1;
        this.isHRC = window.location.href.indexOf('hrc-dashboard') !== -1;
        
        if (this.isHRC) {
            this.bindBreadCrumb();
        }
        else if (this.isGuestHRC) {
            this.guestHrcBreadCrumb();
        }
    }

    
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.title = 'Dashboard'
        this.breadCrumb.pageName = 'Dashboard'
        this.breadCrumb.pageRedirect = "hrc-dashboard";       
    }
       
    guestHrcBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.title = 'Dashboard'
        this.breadCrumb.pageName = 'Dashboard'
        this.breadCrumb.pageRedirect = "guest-hrc-dashboard";       
    }
    
    
    badRequest = (data) => {
        this.spin = false;
         let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
        }
}

