import { ProjectRFQBase } from '@app/database-models'

//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class ProjectRFQViewModel extends ProjectRFQBase {



    //#region Generated Reference Properties
  
    //#endregion Generated Reference Properties
}